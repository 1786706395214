import React from 'react';
import {SEO} from '../components/seo';
import {Layout} from '../components/layout';
import {Link} from "gatsby";
import {useIntl} from 'gatsby-plugin-react-intl'

const NotFoundPage = () => {
    const intl = useIntl().formatMessage;

    return (
        <Layout fullwidth>
            <SEO title={intl({ id: 'notFoundPage.title' })} />

            <section id="menu" className="our-menu bg-clear-day">
                <div className="container col-xl-12 col-xxl-8 px-4 py-5 py-lg-7">
                    <div className="row">
                        <div className="d-flex align-items-center mb-4">
                            <h1 className="fs-3 mb-0">{intl({ id: 'notFoundPage.title' })} {intl({ id: 'notFoundPage.message' })}</h1>
                        </div>
                        <div>
                            <Link to={`/`} className="btn btn-saffron-mango" dangerouslySetInnerHTML={{__html: intl({ id: 'notFoundPage.button' })}} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )

};

export default NotFoundPage;
